import React, { FC, useState, useContext, useEffect, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import NativeSelect from '@material-ui/core/NativeSelect';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import BankingContext from 'contexts/BankingContext';

import { BankingApi, Configuration, TransferApi } from '@optionsai/oai-api-js';
import { makeStyles } from '@material-ui/core/styles';

import config from 'core/api/config';

import Money from 'components/UI/Money';
import { useOktaAuth } from '@okta/okta-react';
import ConfirmDepositDialog from 'components/Banking/ConfirmDepositDialog';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import TransferIcon from 'components/UI/icons/TransferIcon';
import ErrorIcon from 'components/UI/icons/ErrorIcon';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '24px',
    fontWeight: 900,
    marginBottom: theme.spacing(2),
  },
  pageSubtitle: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#828282',
  },
  availableWithdraw: {
    color: '#2C74F6',
    fontSize: '24px',
    fontWeight: 700,
  },
  balanceBox: {
    backgroundColor: '#E8F4FD',
    borderRadius: '5px',
    padding: '10px 12px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
  },
  primaryColor: {
    color: '#2C74F6',
  },
  secondaryColor: {
    color: '#828282',
  },
  fw500: {
    fontWeight: 500,
  },
  fw600: {
    fontWeight: 600,
  },
  fw700: {
    fontWeight: 700,
  },
  fs10: {
    fontSize: '10px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '13px',
    },
  },
  fs11: {
    fontSize: '11px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  fs12: {
    fontSize: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
  },
  fs12Important: {
    fontSize: '12px !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px !important',
    },
  },
  mb12: {
    marginBottom: '12px',
  },
  mb10: {
    marginBottom: '10px',
  },
  link: {
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: '11px',
    fontWeight: '500 !important' as any,
    color: '#2C74F6',
    opacity: 1,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.75,
    },
    transition: 'opacity 0.15s ease-in-out',
    width: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  textButton: {
    textTransform: 'none',
    fontSize: '11px',
  },
  noPaddingLeft: {
    paddingLeft: 0,
    justifyContent: 'flex-start',
  },
  form: {
    width: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    '@supports (width: -moz-available)': {
      maxWidth: '-mox-available',
      width: '-moz-available',
    },
    paddingLeft: '50px',
    [theme.breakpoints.down(775)]: {
      paddingLeft: 0,
    },
  },
  accountInformation: {
    maxWidth: 300,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 260,
    },
  },
  transferTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#2C74F6',
    margin: '21px 0px',
  },
}));

const Deposit: FC = observer(() => {
  const styles = useStyles();

  const { oktaAuth } = useOktaAuth();
  const banking = useContext(BankingContext);

  const [bankingApi, setBankingApi] = useState<BankingApi>();
  const [transferApi, setTransferApi] = useState<TransferApi>();

  const [confirmed, setConfirmed] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [accountId, setAccountId] = useState<number>();
  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [transferError, setTransferError] = useState<{ header?: string, message?: string } | undefined>(undefined);
  const [achWarning, setACHWarning] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();

    try {
      if (!accessToken) {
        throw new Error('Needs access token');
      }

      const configuration = new Configuration({
        basePath: config.basePath,
        accessToken,
      });

      setBankingApi(new BankingApi(configuration));
      setTransferApi(new TransferApi(configuration));
    } catch (e) {
      setError(e as any);
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (!bankingApi) {
      return;
    }

    setLoading(true);

    bankingApi
      .getLinkedAccounts()
      .then((accounts) => banking.setLinkedAccounts(accounts))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [banking, bankingApi]);

  useEffect(() => {
    if (!banking.linkedAccounts) {
      return;
    }

    if (banking.linkedAccounts.length === 0) {
      return;
    }

    setAccountId(banking.linkedAccounts[0].id);
  }, [banking.linkedAccounts]);

  const options = banking.linkedAccounts.map((a) => ({
    value: a.id,
    label: `${a.name} (${a.accountNumber})`,
  }));

  const handleTransfer = async () => {
    if (!transferApi) {
      throw new Error('Transfers not available');
    }

    if (!accountId) {
      throw new Error('Account not set');
    }

    if (!amount) {
      throw new Error('Amount not set');
    }

    setTransferLoading(true);

    // return transferApi
    //   .initiateDeposit({
    //     initiateDeposit: {
    //       accountId,
    //       amount,
    //     },
    //   })
    //   .then(() => setSubmitted(true))
    //   .catch((err) => {


    //     console.log('error', err, JSON.stringify(err))
    //     setTransferError(err.message)
    //   })
    //   .finally(() => setTransferLoading(false));

    const url = `${config.basePath}/transfers/deposits`

    await axios.post(url, {
      accountId,
      amount,
    }, {
      headers: {
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
      }
    }).then(() => setSubmitted(true))
      .catch((err) => {
        let message = err.response?.data?.message || err.message || 'An unknown error occurred'
        let header = undefined

        if (message === 'Insufficient funds to cover deposit') {
          header = 'Deposit Unsuccessful'
          message = `Your deposit attempt has not been successful due to insufficient funds. Please ensure you have sufficient funds available before attempting to deposit to your Options AI account. Contact us at support@optionsai.com for more information.`
        }

        setTransferError({
          header,
          message
        })
      })
      .finally(() => setTransferLoading(false));


  };

  const onSubmit = async () => {
    if (confirmed) {
      if (!amount) {
        throw new Error('Amount not set');
      }

      if (amount <= 1000) {
        setACHWarning(true);

        return;
      }

      return handleTransfer();
    }

    setConfirmed(true);
  };

  const onAmountChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, value: number) =>
      setAmount(value),
    []
  );

  const onAccountSelect = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>) =>
      setAccountId(Number.parseInt(event.target.value)),
    []
  );

  const onCancel = useCallback(async () => setConfirmed(false), []);
  const selectedAccount = banking.linkedAccounts.find(
    (a) => a.id === accountId
  );

  const closeACHWarning = useCallback(() => {
    setACHWarning(false);
  }, []);

  const achWarningCancel = useCallback(() => {
    setACHWarning(false);
    setConfirmed(false);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const disabled = !amount || amount < 100 || !accountId;

  const isMdUp = useMediaQuery('(min-width:1280px)');

  if (submitted) {
    return <Redirect to="/transfers/success/deposit" />;
  }

  return (
    <>
      {transferLoading && (
        <Box
          display={'flex'}
          height={isMdUp ? '100%' : 'calc(100vh - 53px)'}
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <TransferIcon />

            <Typography className={styles.transferTitle}>
              Working on your request
            </Typography>

            <CircularProgress size={24} />
          </Box>
        </Box>
      )}

      {(!!transferError?.message || error) && (
        <Box
          display={'flex'}
          height={isMdUp ? '100%' : 'calc(100vh - 53px)'}
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            maxWidth={310}
            flexDirection="column"
            alignItems="center"
          >
            <ErrorIcon />

            <Typography className={styles.transferTitle}>{transferError?.header || 'Error'}</Typography>

            <div className={styles.mb12}>
              <Typography
                className={`${styles.fs12} ${styles.secondaryColor} ${styles.mb12}`}
              >
                {error ? error : transferError?.message ? transferError.message : 'Unable to process your request at this time'}
              </Typography>

              <Typography className={`${styles.fs12} ${styles.secondaryColor}`}>
                For more information contact support at:{' '}
              </Typography>

              <Typography className={styles.mb12}>
                <a className={styles.link} href="mailto:support@optionsai.com">
                  support@optionsai.com
                </a>
              </Typography>
            </div>

            <Button onClick={refreshPage}>
              <Typography
                className={`${styles.textButton} ${styles.fs12Important} ${styles.primaryColor}`}
              >
                Refresh Page
              </Typography>
            </Button>
          </Box>
        </Box>
      )}

      {!transferLoading && !transferError && (
        <>
          {' '}
          <Box padding={2}>
            <Typography className={styles.pageTitle}>Deposit Funds:</Typography>

            <List>
              <Box paddingY={1}>
                <ListItem disableGutters={true}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h4',
                    }}
                  >
                    From
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {confirmed ? (
                      <Typography variant="h4">
                        {selectedAccount?.name} -{' '}
                        {selectedAccount?.accountNumber}
                      </Typography>
                    ) : (
                      <NativeSelect onChange={onAccountSelect}>
                        {options.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </NativeSelect>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>

              <Box paddingY={1}>
                <ListItem disableGutters={true}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h4',
                    }}
                  >
                    To
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Typography variant="h4">Options AI - 3AI05XXXX</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>

              <Box paddingY={1}>
                <ListItem disableGutters={true}>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h4',
                    }}
                  >
                    Amount
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {confirmed ? (
                      <Typography variant="h4">
                        <Money>{amount}</Money>
                      </Typography>
                    ) : (
                      <CurrencyTextField
                        autoFocus={true}
                        variant="outlined"
                        value={amount}
                        minimumValue="0"
                        placeholder="0.00"
                        onChange={onAmountChange}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>

              <Box pt={1} paddingX={2}>
                <Typography color="textSecondary" variant="body2" align="right">
                  Minimum deposit of $100
                </Typography>
              </Box>

              <Box pt={.5} pb={1} paddingX={2}>
                <Typography color="textSecondary" variant="body2" align="right">
                  Maximum deposit is $50,000 per day
                </Typography>
              </Box>
           
            </List>

            {loading && <Typography>Loading...</Typography>}

            {error && <Typography>{error.message}</Typography>}

            <ButtonGroup fullWidth={true} variant="contained">
              {confirmed && (
                <Button size="large" variant="contained" onClick={onCancel}>
                  Cancel
                </Button>
              )}

              <Button
                size="large"
                color="primary"
                onClick={onSubmit}
                disabled={disabled}
              >
                {confirmed ? 'Submit' : 'Review'}
              </Button>
            </ButtonGroup>
          </Box>
          <ConfirmDepositDialog
            open={achWarning}
            onSubmit={handleTransfer}
            onClose={closeACHWarning}
            onCancel={achWarningCancel}
          />{' '}
        </>
      )}
    </>
  );
});

export default Deposit;
